import React from 'react';
import "./header.css";
import ProfileImg from '../../assets/profile-img.png';
import ProfileImgWebp from '../../assets/profile-img.webp';
import HeaderSocials from './HeaderSocials';

export default function Header() {
  return (
    <div className='header'>
        <div className='container header__container'>
            <h5>Hello I'm</h5>
            <h1>Muraru Mihai</h1>
            <h2>Web Designer & Front-end Developer</h2>

            <HeaderSocials />

            <div className='profile__container'>
                <div className='circle circle_one'></div>
                <div className='circle circle_two'></div>
                <div className='profile'>
                    <picture>
                        <source srcSet={ProfileImgWebp} alt="Muraru Mihai - Web Designer & Front-end Developer" type='image/webp'/>
                        <img src={ProfileImg} alt="Muraru Mihai - Web Designer & Front-end Developer"/>
                    </picture>
                </div>
            </div>


            <a href="#contact" className="scroll__down" aria-label='Go to contact'>Scroll Down</a>
        </div>
    </div>
  )
}
