import React from 'react';
import './about.css';
import AboutImg from '../../assets/about_me.jpg';
import AboutImgWebp from '../../assets/about_me.webp';

export default function About() {
  return (
    <div className='about container' id='about'>
        <div className='left'>
            <h5>Who I am</h5>
            <h2>HELLO. I'm Mihai</h2>

            <p>I'm a developer and web designer with more than 4 years of experience, I can help you develop your online business on all levels.
            Even more, I also like to discover, explore and work with new technologies, because I tend to be very persuasive in all kinds of researches
             that can make better the way I work. I am very attentive to the details and I want to come up with the best solutions for developing web applications.
            </p>

            <a className='contact_btn' href='#contact' aria-label='Contact Form'>Let's Talk</a>

        </div>
        <div className='right'>
            <picture>
                <source srcSet={AboutImgWebp} alt='About me'></source>
                <img src={AboutImg} alt='About me'/>
            </picture>
        </div>
        
    </div>
  )
}
