import React, {Component} from 'react';
import {BsLinkedin, BsInstagram, BsFacebook} from 'react-icons/bs'

const HeaderSocials = () => {
    return (
        <div className="header__socials">
            <a href="https://www.linkedin.com/in/mihai-radu-muraru-36423a150/" target="_blank" aria-label='Linkedin'><BsLinkedin/></a>
            <a href="https://www.instagram.com/mihaimtz777/" target="_blank" aria-label='Instagram'><BsInstagram/></a>
            <a href="https://www.facebook.com/muraru.mihai.14" target="_blank" aria-label='Facebook'><BsFacebook/></a>
        </div>
    );
}

export default HeaderSocials;