import React, {useState} from 'react';
import './services.css';
import {TbHandClick} from 'react-icons/tb';
import {AiFillHtml5, AiFillFacebook, AiOutlineInstagram} from 'react-icons/ai';
import {IoLogoCss3, IoLogoJavascript} from 'react-icons/io';
import {FaReact, FaWordpress} from 'react-icons/fa';
import {MdOutlineDesignServices, MdDevices} from 'react-icons/md';
import {SiPagespeedinsights, SiWoo,SiShopify,SiTiktok} from 'react-icons/si';
import {BsFillPlugFill} from 'react-icons/bs';
import {RiMailSendFill} from 'react-icons/ri';
import Cta from './Cta';

export default function Services() {

    // const [isShown, setIsShown] = useState(false)

  return (
    <>
        <div className='services container' id='services'>
            <div className='service'>
                <p>What i do</p>
                <h2>Services</h2>
                <p>Whether you want a showcase website, an online store or to promote your business, I'll work with you every step of the way to make sure your vision comes to life in the best way possible – something that will truly represent you!</p>
            </div>
            <div className='service' >
                <div className='service__inner'>
                    <div className='service__front'>
                        <p>01.</p>
                        <h3>Web Design</h3>
                        <TbHandClick className='service__icon'/>
                    </div>
                    <div className='service__back'>
                            <div className='service__back-icons'>
                                    <AiFillHtml5  className='service__back-icon'/>
                                    <IoLogoCss3 className='service__back-icon'/>
                                    <IoLogoJavascript className='service__back-icon'/>
                                    <FaReact className='service__back-icon'/>
                                    <FaWordpress className='service__back-icon'/>

                            </div>
                            <div className='service__back-content'>
                                <h4>Need a website?</h4>
                                <div className='service__back-content-item'>
                                    <MdDevices className='service__back-icon'/>
                                    <p> - Responsive</p>
                                </div>
                                <div className='service__back-content-item'>
                                    <MdOutlineDesignServices className='service__back-icon'/>
                                    <p>- Creative</p>
                                </div>
                                <div className='service__back-content-item'>
                                    <SiPagespeedinsights className='service__back-icon'/>
                                    <p>- Fast</p>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div className='service'>
                <div className='service__inner'>
                        <div className='service__front'>
                            <p>02.</p>
                            <h3>E-Commerce</h3>
                            <TbHandClick className='service__icon'/>
                        </div>
                        <div className='service__back'>
                            <div className='service__back-icons'>
                                    <SiWoo  className='service__back-icon'/>
                                    <SiShopify className='service__back-icon'/>
                                
                                </div>
                                <div className='service__back-content'>
                                    <h4>Need a store?</h4>
                                    <div className='service__back-content-item'>
                                        <MdDevices className='service__back-icon'/>
                                        <p> - Responsive</p>
                                    </div>
                                    <div className='service__back-content-item'>
                                        <BsFillPlugFill className='service__back-icon'/>
                                        <p>- All plugins and integrations</p>
                                    </div>
                                    <div className='service__back-content-item'>
                                        <SiPagespeedinsights className='service__back-icon'/>
                                        <p>- Fast</p>
                                    </div>
                            </div>
                        </div>
                    
                </div>
            </div>
            <div className='service'>
                <div className='service__inner'>
                    <div className='service__front'>
                        <p>03.</p>
                        <h3>Digital Marketing</h3>
                        <TbHandClick className='service__icon'/>
                    </div>
                    <div className='service__back'>
                        <div className='service__back-icons'>
                                    <AiFillFacebook  className='service__back-icon'/>
                                    <AiOutlineInstagram className='service__back-icon'/>
                                    <SiTiktok className='service__back-icon'/>
                                    <RiMailSendFill className='service__back-icon'/>
                                </div>
                                <div className='service__back-content'>
                                    <h4>Want to promote your business?</h4>
                                    <div className='service__back-content-item'>
                                        <AiFillFacebook className='service__back-icon'/>
                                        <p> - Facebook Ads</p>
                                    </div>
                                    <div className='service__back-content-item'>
                                        <AiOutlineInstagram className='service__back-icon'/>
                                        <p>- Instagram Ads</p>
                                    </div>
                                    <div className='service__back-content-item'>
                                        <SiTiktok className='service__back-icon'/>
                                        <p>- TikTok Ads</p>
                                    </div>
                            </div>
                    </div>
                    
                </div>
            </div>

        </div>
        <Cta />
    </>
    
  )
}
