import React, {useRef } from 'react';
import './contact.css';
import {RiMailSendLine, RiMessengerLine, RiWhatsappLine} from 'react-icons/ri';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_l2g7nku', 'template_5mq8h6k', form.current, 'Nfl557JZ6Huoz4RLE')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset();
    };

    return (
        <section id="contact" className='container'>
            <div className='contact__heading'>
                <h3>LET'S CONNECT</h3>
                <div className='right'>
                    <h4>I'm always interesed about</h4>
                    <div className='contact__interests'>
                        <p>UX/UI DESIGN <span className='horizontal'></span> <span className='vertical'></span></p>
                        <p>FRONTEND DEVELPOMENT <span className='horizontal'></span> <span className='vertical'></span></p>
                        <p>WORDPRESS <span className='horizontal'></span> <span className='vertical'></span></p>
                        <p>WOOCOMMERCE <span className='horizontal'></span> <span className='vertical'></span></p>
                        <p>SHOPIFY <span className='horizontal'></span> <span className='vertical'></span></p>
                        <p>DIGITAL CONSULTANT <span className='horizontal'></span> <span className='vertical'></span></p>
                        <p>COFFEE <span className='horizontal'></span> <span className='vertical'></span></p>
                        <p>PIZZA <span className='horizontal'></span> <span className='vertical'></span></p>
                    </div>
                    <div className='contact__cta'>
                        <p>ARE YOU MINDING A PROJECT?</p>
                        <h4>CONTACT ME</h4>
                    </div>
                </div>
                
            </div>
            

            <div className="container contact__container">
                <div className="contact__options">
                    <article className='contact__option'>
                        <div>
                            <h4>Email</h4>
                            <RiMailSendLine className='contact__option-icon'/>
                        </div>
                        <h5>contact@murarugraphics.com</h5>
                        <a href='mailto:contact@murarugraphics.com' target='_blank' aria-label='Send a email'>Send an email</a>
                    </article>
                    <article className='contact__option'>
                        <div>
                            <h4>Messenger</h4>
                            <RiMessengerLine className='contact__option-icon'/>
                        </div>
                        <h5>Mihai Muraru</h5>
                        <a href='https://m.me/muraru.mihai.14' target='_blank' aria-label='Send a message'>Send a message</a>
                    </article>
                    <article className='contact__option'>
                        <div>
                            <h4>WhatsApp</h4>
                            <RiWhatsappLine className='contact__option-icon'/>
                        </div>
                        <h5>+40721002903</h5>
                        <a href='https://api.whatsapp.com/send?phone=+40721002903' target='_blank' aria-label='Send a message'>Send a message</a>
                    </article>
                </div>

                <form ref={form} onSubmit={sendEmail}>
                    <input name='name' type='text' placeholder='Your Full Name' required/>
                    <input name='email' type='email' placeholder='Email' required/>
                    <textarea name="message" rows="7" placeholder='Enter your Message' required></textarea>
                    <button type='submit' className='btn btn-primary'>Send Message</button>
                </form>
            </div>
        </section>
    );
}

export default Contact;