import React from 'react'

export default function Cta() {
  return (
    <div className='Cta container'>
        <div>
            <p>Can i help you?</p>
            <a href='#contact' className='contact_btn' aria-label='Go to contact' >CONTACT ME</a>
        </div>
    </div>
  )
}
