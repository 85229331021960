import React from "react";
// icons
import {FaReact, FaWordpress} from 'react-icons/fa';
import {SiPagespeedinsights, SiShopify} from 'react-icons/si';
import {AiFillHtml5} from 'react-icons/ai';
import {IoLogoCss3} from 'react-icons/io';

// images
import MuraruGraphicsImg from '../../assets/opt/murarugraphics_react.jpg';
import MuraruGraphicsWebp from '../../assets/opt/murarugraphics_react.webp';
import DemoHtmlImg from '../../assets/opt/template_html.jpg';
import DemoHtmlWebp from '../../assets/opt/template_html.webp';
import IstructureImg from '../../assets/opt/istructure.jpg';
import IstructureWebp from '../../assets/opt/istructure.webp';



export const projects = [
    {
        id: 1,
        name: "murarugraphics.com",
        technologies: [<FaReact />,<SiPagespeedinsights />],
        url: "https://murarugraphics.com",
        img: MuraruGraphicsImg,
        imgWebp: MuraruGraphicsWebp
    },
    {
        id: 2,
        name: "Demo HTML",
        technologies: [<FaReact />,<SiPagespeedinsights />],
        url: "https://murarugraphics.com",
        img: DemoHtmlImg,
        imgWebp: DemoHtmlWebp
    },
    {
        id: 3,
        name: "Istructure",
        technologies: [<FaWordpress/>, <AiFillHtml5 />, <IoLogoCss3 />],
        url: "https://murarugraphics.com",
        img: IstructureImg,
        imgWebp: IstructureWebp
       
    }
    // {
    //     id: 4,
    //     name: "murarugraphics3",
    //     technologies: [<FaWordpress/>, <AiFillHtml5 />, <IoLogoCss3 />],
    //     url: "https://murarugraphics.com",
    //     details: AboutMeImg
    // },
    // {
    //     id: 5,
    //     name: "murarugraphics3",
    //     technologies: [<SiShopify />],
    //     url: "https://murarugraphics.com",
    //     details: AboutMeImg
    // }
]