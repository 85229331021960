import React from 'react';
import "./nav.css";
import {AiOutlineHome, AiOutlineUser} from 'react-icons/ai';
import {BiBook, BiMessageRoundedDetail} from 'react-icons/bi';
import {GrServices} from 'react-icons/gr';
import {useState} from 'react';
    

export default function Nav() {

    const [activeNav, setActiveNav] = useState('#')
    // menu structure
     return (
        <nav>
             <a href="#"
               onClick={() => setActiveNav('#')}
               className={activeNav === '#' ? 'active' : ''}
               aria-label='Header'   > <AiOutlineHome/> </a>
            <a href="#about"
                  onClick={() => setActiveNav('#about')}
                  className={activeNav === '#about' ? 'active' : ''}
                  aria-label='About Section' > <AiOutlineUser/> </a>
            <a href="#services"
               onClick={() => setActiveNav('#services')}
               className={activeNav === '#services' ? 'active social__stroke_primary' : 'social__stroke_primary'}
               aria-label='Services Section' > <GrServices/> </a>
            <a href="#portfolio"
               onClick={() => setActiveNav('#portfolio')}
               className={activeNav === '#portfolio' ? 'active' : ''}
               aria-label="Portfolio Section" > <BiBook/> </a>
            <a href="#contact"
               onClick={() => setActiveNav('#contact')}
               className={activeNav === '#contact' ? 'active' : ''}
               aria-label="Contact Section" > <BiMessageRoundedDetail/> </a>
        </nav>
     )
}
