import React,{useState} from 'react';
import './portfolio.css';
import {projects} from './portfolio_data';


export default function Portfolio() {

    const [isHover,setIsHover] = useState(false);
    const [isThis, setIsThis] = useState(1)

  return (
    <div className='portfolio container' id='portfolio'>
        <div className='portfolio__title'>
            <h2>Portfolio</h2>
            <div className='portfolio__form'></div>
        </div>
        <div className='portfolio__content'>
            <div className='text'>
                <p>A little part of my work</p>
            </div>

            <div className='projects'>
                {
                    
                    projects.map(({id,name,technologies,url,img,imgWebp}) => {
                       
                        return (
                            <div className='project' key={id} onMouseEnter={() =>  setIsThis(id)} onMouseLeave={() =>  setIsThis(0)}>
                                    <p >{name}</p>
                                    <div className='project__technologies'>
                                        {
                                            technologies.map((technology, i) => {
                                                return (
                                                    <div className='project__technology' key={i}>
                                                        {technology}
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                    { isThis==id && (
                                        <div className='project__details'>
                                            <picture>
                                                <source srcSet={imgWebp}></source>
                                                <img src={img} />
                                            </picture>
                                            
                                        </div>
                                    ) }
                    
                            </div>
                        )
                    })
                }
            </div>


          
        </div>
        
    </div>
  )
}
