import logo from './logo.svg';
import './App.css';
import Nav from './components/nav/Nav';
import Header from './components/header/Header';
import Technologies from './components/tehnologies/Technologies';
import Services from './components/services/Services';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Portfolio from './components/portfolio/Portfolio';
import Testimonials from './components/testimonials/Testimonials';

function App() {
  return (
    <div className="App">
        <Nav />
        <Header />
        <Technologies />
        <About />
        <Services />
        <Portfolio />
        <Contact />
    </div>
  );
}

export default App;
